<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <YandexTrackContainer />
        </ion-content>
    </ion-page>
</template>

<script>
import YandexTrackContainer from '@/pages/Tabs/Routes/YandexRouteContainer.vue';

export default {
    name: 'RoutesIndex',
    components: { YandexTrackContainer },
};
</script>

<style scoped>

</style>
